.circle {
    width:70%;
    height: 45%;
    border-radius: 100%;
    font-size: 3rem;
    font-weight: bold;
    color: #EFEFEF;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    flex-direction: column;
    margin: auto;
    display: flex;
  }

.column {
    width: 40%;
    display: flex;
    flex-direction: column;
    height: 100vh;
}