.home-section {
    width: 100%;
}

.button {
    width: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: auto;
    margin-right: auto;
    color: #EFEFEF;
    background-color: #ddb01a;
    border-radius: 10%;
    height: 4em;
    border: none;
    font-weight: bold;
    font-size: x-large;
}

.home-section-child p {
    font-size: 3em;
    margin-bottom: 0px;
    text-align: center;
}